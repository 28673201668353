import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { WebapiService } from '../services/webapi.service'; 

@Component({
  selector: 'app-blogdetails',
  templateUrl: './blogdetails.component.html',
  styleUrls: ['./blogdetails.component.css']
})
export class BlogdetailsComponent implements OnInit {
  
  slug:any;
  blogList:any={};
  // serviceList:any={};
  constructor(private router:ActivatedRoute,
    private WebapiService:WebapiService,
    private urouter:Router) { }


  ngOnInit(): void {

    let pths= this.urouter.url.split('/');
    // console.log(pths[1]);
    // console.log(this.urouter.url);
     this.router.queryParams.subscribe(params => {
       //  console.log(params); // { order: "popular" 
       this.slug = params.slug;
        // console.log(this.order); // popular
       }
     );
 
   this.getAllBlogs(pths[2])
  }
  getAllBlogs(slug:any){
    this.WebapiService.getAllBlogs(slug).subscribe((res: any) => {
    console.log(res);
    this.blogList = res[0]; 
    
   });

 }
}
