import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../services/webapi.service'; 

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  categoryList:any;
  sliderList:any;
  blogList:any;
  url: any;
  constructor(private WebapiService:WebapiService) { }

  ngOnInit(): void {
    this.url = this.WebapiService.url1;
    this.getSlider();
    this.getCategory();
this.getAllBlogs();

  }

  getSlider(){

    this.WebapiService.getSlider().subscribe((res: any) => {
      // console.log(res);
      this.sliderList = res;
   
    });
  }
  getCategory(){

    this.WebapiService.getCategory().subscribe((res: any) => {
      // console.log(res);
      this.categoryList = res;
    //for (let category of res) {
  //   this.getServices(this.categoryList);
      // this.categoryList = res.data;
    });
  }
  getAllBlogs(){
    this.WebapiService.getAllBlogs().subscribe((res: any) => {
    // console.log(res);
    this.blogList = res; 
   });

 }
}
