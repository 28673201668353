import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../services/webapi.service'; 
import { ActivatedRoute,Router } from '@angular/router';
@Component({
  selector: 'app-industry',
  templateUrl: './industry.component.html',
  styleUrls: ['./industry.component.css']
})
export class IndustryComponent implements OnInit {

  slug:any;
  categoryList:any;
  getIndustryList:any;
  pageList: any;
  url:any;
  constructor(private router:ActivatedRoute,private WebapiService:WebapiService) { }

  ngOnInit(): void {
    this.url = this.WebapiService.url1;
    // this.getPage(6);
  

    this.router.queryParams.subscribe(params => {
      console.log(params); // { order: "popular" }

      this.slug = params.slug;
     // console.log(this.order); // popular
    }
  
  );

    this.getIndustry(this.slug);
    //console.log(this.categoryList);
    this.getPage(3);
    
  }


  getIndustry(id:any){
        this.WebapiService.getIndustry(id).subscribe((res: any) => {
          console.log(res);
           this.getIndustryList = res;
    
    });
  //}
  }
  
  getPage(id:any){
    this.WebapiService.getPage(id).subscribe((res: any) => {
      console.log(res);
       this.pageList = res;

});
}


}
