<style>
   .list-group-item.active a{
      color: #fff;
   }
</style>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div class="modal-dialog" role="document">
     <div class="modal-content">
       <div class="modal-header">
         <h5 class="modal-title" id="exampleModalLabel">Schedule a Meeting</h5>
       </div>
       <div class="modal-body">
         <form #sForm = "ngForm" (ngSubmit)="scheduleMeeting(formData)">
          <label>Name</label>
          <input type="text" class="form-control" name="name" [(ngModel)]="formData.name">
          <label>Email</label>
          <input type="text" class="form-control" name="name" [(ngModel)]="formData.email">
          <label>Phone Number</label>
          <input type="text" class="form-control" name="name" [(ngModel)]="formData.phone">
          <label>Message</label>
          <input type="text" class="form-control" name="name" [(ngModel)]="formData.message">
          <br>
          <button type="submit" class="btn btn-danger">Submit</button>  
         </form>
       </div>
       <div class="modal-footer">
         <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
       </div>
     </div>
   </div>
 </div>

<section class="" style="padding-bottom: 50px">
    <div class="teamDetailHeading">
       <div class="container">
          <div class="row">
             <div class="col-12 text-center">
                <h2 class="text-white">Team Details</h2>
                <ul class="breadcrumb">
                   <li><a href="">Home</a></li>
                   <li><a href="#">TEAM DETAILS</a></li>
                </ul>
             </div>
          </div>
       </div>
    </div>
 </section>
 <section class="teamDetailSlide"></section>
 <section class="section">
    <div class="container">
       <div class="row">
          <div class="col-12 col-md-3 order-2">
             <div class="list-group">
                <div class="team-column">
                   <ul class="list-unstyled nameListHead text-white">
                      <li class="teamactnew">
                         <h5 class="mb-0">OUR TEAM</h5>
                      </li>
                   </ul>
                   <ul class="list-unstyled nameList list-group">
                     <li  *ngFor="let data of teamListall"   class="list-group-item list-group-item-action"><a href="/teamdetails?id={{data.id}}" routerLinkActive="active"  class="">{{data.fname}}</a></li>
                     
                     <!-- <li class="5889 teamact list-group-item list-group-item-action">
                         <a href="" class="active">Vikas Chauhan</a></li> -->                      
                      <!-- <li class="list-group-item list-group-item-action"><a href="#item3" class="">Manish Kumar</a></li>
                      <li class="list-group-item list-group-item-action"><a href="#item4" class="">Isha Sharma</a></li>
                      <li class="list-group-item list-group-item-action"><a href="#item5" class="">Ankit Kaushik</a></li>
                      <li class="list-group-item list-group-item-action"><a href="#item6" class="">Vinay Kumar Gupta</a></li>
                      <li class="list-group-item list-group-item-action"><a href="#item7" class="">Pallavi Jha</a></li>
                      <li class="list-group-item list-group-item-action"><a href="#item8" class="">Roshni Anand</a></li> -->
                   </ul>
                </div>
             </div>
          </div>
          <div class="col-12 col-md-9 order-1">
             <div class="col-3 float-left">
                <img src="https://ingage.in/vmr/demo/admin/images/team_image/{{teamList.image}}" class="img-fluid rounded-circle" />
                <ul class="list-unstyled teaminfoul mt-2 ml-2">
                   <!-- <li class="mb-2">
                     
                   </li> -->
                   <li>
                      <a class="" href="{{teamList.state}}
 " target="_blank"><img src="assets/img/linkedin.svg" style="width: 20px; height: 20px;" />
                      </a>
                   </li>
                   <li>
                    <a class="ml-3" href="tel:{{teamList.phone}}" ><img src="assets/img/phone.svg" style="width: 22px; height: 22px;" />
                    </a>
                 </li>
                  
                </ul>
               
            <div style=" display: flex; align-items: center;justify-content: center;margin-bottom: 8px;">
                    <img src="assets/img/location.svg" style="width: 25px;height: 25px;margin-right: 5px;" />
                    <span class="">{{teamList.city}}</span>
                </div>
                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                  Schedule a Meeting
                </button>
                <!-- <button class="btn text-white orange2 borderRad1">Schedule a Meeting</button> -->
             </div>
             <div class="col-9 my-auto float-left">
                <div class="personName">
                   <h2>{{teamList.fname}}</h2>
                   <h6>{{teamList.lname}}</h6>
                </div>
                <div class="memberContent">
                  <!-- <h2>{{teamList.about}}</h2> -->
                   <div class="new-profile-content"  innerHtml="{{teamList.address}}">
<!--                      
                      <p class="text-justify">
                         <strong>>{{teamList.fname}} {{teamList.lname}}</strong> is a Partner of VMR & Company, Chartered Accountants and director at VMR Corporate Advisors Pvt. Ltd. He is a Commerce graduate from Delhi University and Chartered Accountant by qualification.
                      </p>
                      <p class="text-justify">
                         Vikas possesses rich professional experience of more than 6 years post qualification in handling International Corporate Tax matters of domestic/ multi-national enterprises. He has been involved in advising clients on various domestic and international tax matters for mitigating tax exposures and ensuring compliance. He has served several multi-national and domestic clients in a cross-section of industries on diverse matters relating to Entry Level Strategy in India, Income Tax, Goods & Service Tax(GST), Business Acquisitions, Business Consolidations, Re-structureing. 
                      </p>
                      <p class="text-justify">
                         He possesses rich experience in the field of Audit & Assurance services and has served across all industries, clients varies from domestic companies and Multi National Companies. His areas of expertise encompass Statutory & Internal Audit, Risk Advisory, Investigation, Forensic Audit, Due Deligence, Tax Audit and IFRS services.  
                      </p> -->
                   </div>
                </div>
                
             </div>
             <!-- col-9 -->
             <div class="">
               
                <div class="row">
                    <div class=" col-12 text-left">
                        <div class="mr-auto" style="background: linear-gradient(#3f96fd 0%, #3999ff 100%); width: 150px; height: 5px; margin-bottom: 20px;"></div>
                        <h6 class="">Related Articles</h6>
                        <h2 class="mb-4"><b>Insights</b></h2>
                    </div>

                <div class="col-sm-4 col-4 rounded text-center ml-1 mx-auto" *ngFor="let blog of blogList">
                    <a class="text-dark bg-white singleblog" routerLink="/blogdetails">
                        <img src="{{url}}images/blog_image/{{blog.image}}" class="img-fluid">
                        <div class="blog_text">
                            <h4 class="">{{blog.title}}</h4>
                            <p class="mb-4">
                              {{blog.short_desc}}
                            </p>
                        </div>
                    </a>
                </div> 
                <!-- <div class="col-sm-4 col-4 rounded text-center ml-1 mx-auto">
                    <a class="text-dark bg-white singleblog" >
                        <img src="assets/img/ca_services1.jpg" class="img-fluid">
                        <div class="blog_text">
                            <h4 class="">Blog Title</h4>
                            <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</p>
                        </div>
                    </a>
                </div> 
                <div class="col-sm-4 col-4 rounded text-center ml-1 mx-auto">
                    <a class="text-dark bg-white singleblog" >
                        <img src="assets/img/ca_services1.jpg" class="img-fluid">
                        <div class="blog_text">
                            <h4 class="">Blog Title</h4>
                            <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</p>
                        </div>
                    </a>
                </div>  -->
                </div>
            </div>
          </div>
       </div>
    </div>
 </section>
 <section class="section"></section>