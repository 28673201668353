<section>
    <div class="container-fluid px-0">
        <div id="image-slider" class="splide">
            <div class="splide__track">
                <ul class="splide__list">
                    <!-- <li class="splide__slide overlays" *ngFor="let data of sliderList"> 
                        <div class="text-white container heroText animate__animated animate__fadeIn animate__delay-1-5s">
                            <p class="mb-0">ARE YOU A START-UP? LEAVE ALL YOUR ACCOUNTING & TAX WORRIES TO US</p>
                            <p class="display-4 mb-0 mb-sm-0 mb-md-4 fontWeight ">BEST CA FIRM IN DELHI</p>
                            <p class="display-4 mb-0 mb-sm-0 mb-md-4 fontWeight">{{data.title}}</p>
                            <p class="w-75 text-justify">{{data.content}}
                            </p>
                        </div>
                        <img src="{{url}}images/slider/{{data.image}}" class="img-fluid animate__animated animate__fadeIn animate__delay-1s" />
                    </li> -->
                    <li class="splide__slide overlays">
                        <div class="text-white heroText container">
                            <p class="mb-0">ARE YOU A START-UP?</p>
                         
                            <p class="title display-4 mt-0 mb-0 mb-sm-0 mb-md-4 fontWeight">VMR- ONE STOP DESTINATION FOR<br>ALL YOUR TAX, LAW, AUDIT & <br>CONSULTING NEEDS</p>
                            <!-- <p class="text-justify w-75">
                                VMR Advisors has been set up to serve clients of stature and profile. The office is staffed by a combination of recruits, experienced professionals trained with them and expert associates. In this way,
                                control is maintained over methodology and quality while capitalising on local business knowledge and understand clients requirement to serve them with utmost quality.
                            </p> -->
                            <a  [routerLink]="['/contact']" routerLinkActive="active" class="btn text-border btn-slider d-none">Contact Us</a>
                        </div>
                        <img src="assets/img/slide1-min.jpg" class="img-fluid" />
                    </li>
                    <li class="splide__slide overlays">
                        <div class="text-white heroText container">
                            <p class="mb-0">Managing Accounts can be hassle for a startup!</p>
                         
                            <p class="title display-4 mt-0 mb-0 mb-sm-0 mb-md-4 fontWeight">We Manage Startups & Business Finances</p>
                            <p class="text-justify w-75">
                                VMR Advisors has been set up to serve clients of stature and profile. The office is staffed by a combination of recruits, experienced professionals trained with them and expert associates. In this way,
                                control is maintained over methodology and quality while capitalising on local business knowledge and understand clients requirement to serve them with utmost quality.
                            </p>
                             <a  [routerLink]="['/contact']" routerLinkActive="active" class="btn text-border btn-slider d-none">Contact Us</a>
                        </div>
                       
                        <img src="assets/img/slide2-min.jpg" class="img-fluid" />
                    </li>
                    <li class="splide__slide overlays">
                        <div class="text-white heroText container">
                            <p class="mb-0">ARE YOU A START-UP? LEAVE ALL YOUR ACCOUNTING & TAX WORRIES TO US</p>
                         
                            <p class="title display-4 mt-0 mb-0 mb-sm-0 mb-md-4 fontWeight">ONE STEP FOR ALL YOUR FINANCIAL<br> GOALS</p>
                            <p class="text-justify w-75">
                                VMR Advisors has been set up to serve clients of stature and profile. The office is staffed by a combination of recruits, experienced professionals trained with them and expert associates. In this way,
                                control is maintained over methodology and quality while capitalising on local business knowledge and understand clients requirement to serve them with utmost quality.
                            </p>
                            <a  [routerLink]="['/contact']" routerLinkActive="active" class="btn text-border btn-slider d-none">Contact Us</a>
                        </div>
                        <img src="assets/img/slide3-min.jpg" class="img-fluid" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<!--SECTION 3-->

<!--SECTION 4-->
<section class="section  animate__animated animate__fadeIn animate__delay-1s top_pad_0">
    <div class="container">
        <!--SERVICES-->
        <div class="row services">
            <div class="col-sm-6 col-12 mb-0 mb-sm-5 text-center text-sm-left">
                <div class="d-none d-sm-block" style="background: linear-gradient(#3f96fd 0%, #3999ff 100%); width: 150px; height: 5px; margin-bottom: 20px;"></div>
                <h6 class="mb-1 text-uppercase">We Deliver the Best Services</h6>
                <h2 class="mb-4"><b>SERVICES</b> OFFERING</h2>
            </div>
            <div class="col-sm-6 col-12 mb-5 text-center text-sm-right">
                <!-- <button class="btn text-white orange2 borderRad1">View All</button> -->
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3 col-6 rounded text-center mr-1 mx-auto" *ngFor="let data of categoryList">
                <div class="text-dark bg-white vmrService">
                    <div style="display: inline-block; position: relative;">
                        <img src="assets/img/services-shape.svg" class="shape1" />
                        <img src="assets/img/services-shape-2.svg" class="shape2" />
                        <i class="lni lni-apartment text-white service_icon"></i>
                    </div>
                    <h4 class="">{{data.category_name}}</h4>
                    <!-- <p class="mb-4">We use our skills gained over a period of time to help your work to be smarter, quicker & focused.</p> -->
                    <a  routerLink="/category" [queryParams]="{'slug':data.slug}">Know More <i class="lni lni-chevron-right"></i></a>
                </div>
            </div>
           
        </div>
    </div>
</section>
<!--SECTION 5-->
<section class="section gray2  animate__animated animate__fadeIn animate__delay-1s" style="padding: 100px 0;">
    <!--ABOUT-->
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-sm-7 px-sm-3 px-md-0">
                <div class="mr-auto" style="background: linear-gradient(#3f96fd 0%, #3999ff 100%); width: 150px; height: 5px; margin-bottom: 20px;"></div>
                <h6 class="">ABOUT OUR HISTORY</h6>
                <h2 class="mb-4">Grow Your <b>Business</b> With Us</h2>
                <p class="mb-5 text-justify">
                    VMR Advisors has been set up to serve clients of stature and profile. The office is staffed by a combination of recruits, experienced professionals trained with them and expert associates. In this way, control is
                    maintained over methodology and quality while capitalising on local business knowledge and understand clients requirement to serve them with utmost quality.
                </p>
                <a [routerLink]="['/about']" routerLinkActive="active"  class="small text-dark" style="text-decoration: none;">READ MORE <i class="fas fa-caret-right"></i></a>
            </div>
            <div class="offset-1 col-4 d-none d-sm-block">
                <img src="assets/img/about.svg" alt="about" class="w-100" />
            </div>
        </div>
    </div>
</section>
<section class="section  animate__animated animate__fadeIn animate__delay-1s">
    <div class="container">
        <div class="row mb-5">
            <div class="col text-left">
                <div class="mr-auto" style="background: linear-gradient(#3f96fd 0%, #3999ff 100%); width: 150px; height: 5px; margin-bottom: 20px;"></div>
                <h6 class="mb-1">Why Us</h6>
                <h2>OUR <b>Features</b></h2>
            </div>
        </div>
        <div class="row mb-5">
            
    
            <div class="col-sm-4 col-12 bg-white py-4 px-4 rounded mb-3">
                <div class="why-single">
                    <img src="assets/img/monitoring.svg" alt="" class="mb-3" />
                    <!-- <h5 class="">Our Vision</h5> -->
                    <h5 class="">Co-ordination & Continuity</h5>
                    <p class="small">There is nothing more frustrating to a productive business relationship than having different points of contact with no coordination amongst them or continuity in serving. It is our policy for business consulting, audit,
                        tax and regulatory reporting services to assign a single lead partner who is responsible for all of our services to the client.</p>
                
                 </div>
            </div>
            <div class="col-sm-4 col-12 py-4 px-4 text-dark">
                 <div class="why-single">
                    <img src="assets/img/goal.png" alt="" class="mb-3" />
                    <!-- <h5 class="">Our Mission</h5> -->
                    <h5 class="">Industry Expertise</h5>
                    <p class="small">
                        Our industry knowledge, achieved through continuous training and experience, is built on a foundation of technical excellence - a prerequisite for all our professional staff. Our industry specialization gives us a
                        hands‑on experience of a variety of environments.
                    </p>
                </div>
            </div>
            <div class="col-sm-4 col-12 py-4 px-4 text-dark">
                 <div class="why-single">
                    <img src="assets/img/target.png" alt="" class="mb-3" />
                    <h5 class="">Cost Effective</h5>
                    <p class="small">
                        As consultants, we help our clients to avoid duplication and minimize costs, and deliver services that are cost effective and add value to business.
                    </p>
                </div>
            </div>
         </div>
         </div>
</section>


<section class="section gray2  animate__animated animate__fadeIn animate__delay-1s" style="padding: 100px 0;">
    <!--blog-->
    <div class="container">
       
        <div class="row">
            <div class="col text-left">
                <div class="mr-auto" style="background: linear-gradient(#3f96fd 0%, #3999ff 100%); width: 150px; height: 5px; margin-bottom: 20px;"></div>
                <h6 class="">Knowledge Base For You</h6>
                <h2 class="mb-4"><b>Insights</b></h2>
            </div>
        </div>
         <div class="row">
            <div class="col-sm-4 col-12 mb-3 rounded text-center ml-1 mx-auto" *ngFor="let blog of blogList">
                <a class="text-dark bg-white singleblog"  routerLink="/blog-detail/{{blog.slug}}"  >
                    <img src="{{url}}images/blog_image/{{blog.image}}" class="img-fluid">
                    <div class="blog_text">
                        <h4 class="">{{blog.title}}</h4>
                        <div class="mb-4 d-none d-sm-block"  innerHtml="{{blog.short_desc}}"></div>
                    </div>
                </a>
            </div> <!-- single blog -->
             <!-- <div class="col-sm-4 col-4 rounded text-center ml-1 mx-auto">
                <a class="text-dark bg-white singleblog" href="">
                    <img src="assets/img/ca_services1.jpg" class="img-fluid">
                    <div class="blog_text">
                        <h4 class="">Blog Title</h4>
                        <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</p>
                    </div>
                </a>
            </div> single blog -->
             <!-- <div class="col-sm-4 col-4 rounded text-center ml-1 mx-auto">
                <a class="text-dark bg-white singleblog" href="">
                    <img src="assets/img/ca_services1.jpg" class="img-fluid">
                    <div class="blog_text">
                        <h4 class="">Blog Title</h4>
                        <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</p>
                    </div>
                </a>
            </div> single blog -->
         </div>
    </div>
</section>

<!--SECTION 6-->
<!-- <section class="section" style="background-image: linear-gradient(to right, #5010d9, #3a5cf9);">
    <div class="container">
        <div id="text-slider" class="splide">
            <div class="splide__track">
                <ul class="splide__list text-white text-center">
                    <li class="splide__slide">
                        <div class="py-2">
                            <p>
                                "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptas numquam beatae, sit rem fugiat ipsam temporibus! Est neque ad, temporibus blanditiis nulla officia minima? Magni esse sequi autem odit
                                eaque!"
                            </p>
                            <div class="" style="height: 100px;">
                                <img src="assets/img/item1.jpg" class="rounded-circle" width="100" style="position: relative; top: 0;" />
                            </div>
                            <p>Jhon Doe, General Manager</p>
                        </div>
                    </li>
                    <li class="splide__slide">
                        <div class="py-2">
                            <p>
                                "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptas numquam beatae, sit rem fugiat ipsam temporibus! Est neque ad, temporibus blanditiis nulla officia minima? Magni esse sequi autem odit
                                eaque!"
                            </p>
                            <div class="" style="height: 100px;">
                                <img src="assets/img/item2.jpg" class="rounded-circle" width="100" style="position: relative; top: 0;" />
                            </div>
                            <p>Jhon Doe, General Manager</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section> -->
<!--SECTION 7-->
<section>
    <!-- <img src="assets/img/footer.jpg" alt="" class="img-fluid" /> -->
    <div class="video-wrapper"  data-aos="fade-down" data-aos-delay="700">
        <video autoplay muted loop playsinline preload="metadata" style="width: 100%;border-radius: 5px;box-shadow: 2px 8px 20px 0px #565656;/* position: absolute; *//* overflow: hidden; */z-index: 999;margin-top: -290px;">
            <!-- <source src="assets/img/office_vid.mp4" type="video/mp4"> -->
            <source src="https://res.cloudinary.com/drd5qhqme/video/upload/v1633836161/office_vid_aoonyu.mp4" type="video/mp4">
        </video>
      </div>
</section>
<!--SECTION 8-->