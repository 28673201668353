<section>
    <div class="careerHeading" *ngFor="let p of pageList"   style="background-image: url('{{url}}images/page_image/{{p.image}}') ;">
       <div class="container">
          <div class="row">
             <div class="col-12 text-center text-white">
                <h2>{{p.title}}</h2>
                <h6 class="">{{p.content}}</h6>
                <!-- <img src="{{url}}images/page_image/{{p.image}}" alt="carrer page"> -->
                <!-- <h2 class="text-white">Team</h2> -->
                <ul class="breadcrumb">
                   <li><a href="/">Home</a></li>
                   <li><a href="#">Career</a></li>
                </ul>
             </div>
          </div>
       </div>
    </div>
 </section>
 <section class="teamSlide"></section>
 <section>
    <div class="container">
       <div class="row">
          <div class="col-12 col-md-8 section">
             <div class="careerStart pr-5">
             <div style="background: linear-gradient(#3f96fd 0%,#3999ff 100%);width: 150px;height: 5px;margin-bottom: 10px;">
                </div>
             <h2 class="mb-4"><b>Career Start</b> With VMR</h2>
                <p class="text-justify">A great opportunity to work with the best CA firm in the city.</p>
                <a onClick="scrollToBottom()" class="btn text-white orange2 btn-sm">View Openings</a>
             </div>
          </div>
          <div class="col-12 col-md-4 section">
             <div class="careerStartImg">
                <img src="assets/img/undraw_revenue_3osh.svg" class="img-fluid">
             </div>
          </div>
          <!-- <div class="col-12 col-md-4 section">
             <img src="assets/img/shortlist.svg" class="img-fluid">
          </div>
          <div class="col-12 col-md-8 section">
             <div class="pl-5">
                <div class="headOne mb-3">
                   <h2>We are just getting started </h2>
                   <p class="text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla a velit nec nibh condimentum varius ac nec mauris. Quisque id est in ligula dapibus egestas sed vitae est. Maecenas et ornare lectus. Curabitur mauris ligula, dapibus et cursus eget, bibendum ut nibh. Cras erat enim, pretium vel rhoncus et, tempor sed lectus. Curabitur mi odio, tincidunt a finibus et, rutrum vel nisl. Proin sollicitudin neque non volutpat tincidunt. Nullam non luctus orci. Morbi tristique interdum ligula, facilisis lobortis ante convallis interdum. Phasellus lorem leo, facilisis quis euismod at, ornare quis augue. Curabitur cursus, massa a varius facilisis, turpis sapien ornare.</p>
                </div>
                <div class="headTwo">
                   <h2>HOW WE HIRE</h2>
                   <p class="text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla a velit nec nibh condimentum varius ac nec mauris. Quisque id est in ligula dapibus egestas sed vitae est. Maecenas et ornare lectus. Curabitur mauris ligula, dapibus et cursus eget, bibendum ut nibh. Cras erat enim, pretium vel rhoncus et, tempor sed lectus. Curabitur mi odio, tincidunt a finibus et, rutrum vel nisl. Proin sollicitudin neque non volutpat tincidunt. Nullam non luctus orci. Morbi tristique interdum ligula, facilisis lobortis ante convallis interdum. Phasellus lorem leo, facilisis quis euismod at, ornare quis augue. Curabitur cursus, massa a varius facilisis, turpis sapien ornare.</p>
                </div>
             </div>
          </div> -->
          <div class="col-12 px-0" style="margin-bottom: 10rem;">
             <div style="background: linear-gradient(#3f96fd 0%,#3999ff 100%);width: 150px;height: 5px;margin-bottom: 10px;">
                </div>
             <h2 class="mb-4"><b>Current</b> Opening</h2>
             <div *ngFor="let cr of carrerList; let i = index" id="accordion{{i+1}}">
                <div class="card">
                   <div class="card-header p-0">
                      <a class="card-link" data-toggle="collapse" href="#collapse{{i+1}}" style="padding: 0.75rem 1.25rem!important;display: block!important;color: #fff;background-color: #3f96fd;">
                     {{cr.title}}
                      </a>
 
                   </div>
                   <div id="collapse{{i+1}}" class="collapse" data-parent="#accordioni+1">
                      <div class="card-body">
                         <div class="card-body">
                            <div class="d-flex justify-content-end py-2">
                               <button class="btn orange2 text-white btn-sm" (click)="getId(cr.id)" data-toggle="modal" data-target="#myModal">Apply Now</button>
                            </div>
                            <div>
                              <!-- <p><strong><span style="font-size:18px">Job Summary:</span></strong></p>
                              <p><span style="font-size:16px">VMR is currently seeking a high-level content writer to write industry related topics, and informational blog articles related to legal field or any current topic. Work products range from 500-1500 words and cover topics including legal subject matters, review of industry trends, and summations of recent legal cases. Our clients are law firms, solo practitioners and in-house counsel. Readers may be any in legal industry.<br><br>Thorough research, competent writing and ability to meet deadlines are a must. Writing must have high level vocabulary and be free from grammatical errors and plagarism. Experience with SEO/ legal marketing is a plus.<br><br>Flexible schedule within deadlines, work from home/ remote. Please submit resume, cover letter and two writing samples.</span> -->
                            {{cr.job_summary}}
                              </div>
<br><br>
<div innerHtml="{{cr.job_desc}}">

</div>
<div innerHtml="{{cr.job_specs}}">

</div>
<!-- <strong><span style="font-size:18px">Job Description:</span></strong></p>
                            <ul>
                               <li><span style="font-size:16px">Research industry-related topics (combining online sources, interviews and studies)</span></li>
                               <li><span style="font-size:16px">Write clear marketing copy to promote our products/ services</span></li>
                               <li><span style="font-size:16px">Proofread and edit blog posts before publishing</span></li>
                               <li><span style="font-size:16px">Conduct simple keyword research and use SEO guidelines to increase web traffic</span></li>
                               <li><span style="font-size:16px">Promote content on social media</span></li>
                               <li><span style="font-size:16px">Identify customers’ needs and gaps in our content and recommend new topics</span></li>
                               <li><span style="font-size:16px">Ensure all-around consistency (style, fonts, images and tone)</span></li>
                               <li><span style="font-size:16px">Update website content as needed</span></li>
                            </ul> -->
                            <!-- <p><strong><span style="font-size:18px">Job Specifications:</span></strong></p>
                            <p><strong><em><span style="font-size:16px">Qualifications:</span></em></strong></p>
                            <ul>
                               <li><span style="font-size:16px">Graduated in BA / MA in Journalism and Communication or BSc degree in Journalism, Marketing or relevant field / Experience (or) Visual Communication/Mass Communication / Any degree</span></li>
                               <li><span style="font-size:16px">Experience- 02- 05 years</span></li>
                               <li><span style="font-size:16px">Employment Type – Full Time</span></li>
                               <li><span style="font-size:16px">Number of Opening: 1</span></li>
                               <li><span style="font-size:16px">Location- New Delhi</span></li>
                            </ul>
                            <p><strong><em><span style="font-size:16px">Required Skills:</span></em></strong></p>
                            <ul>
                               <li><span style="font-size:16px">Proven work experience as a Content writer</span></li>
                               <li><span style="font-size:16px">Excellent writing skills in English</span></li>
                               <li><span style="font-size:16px">Hands on experience with MS Office and WordPress</span></li>
                               <li><span style="font-size:16px">Excellent technical knowledge of HTML and web publishing</span></li>
                               <li><span style="font-size:16px">Knowledge of SEO and web traffic metrics</span></li>
                               <li><span style="font-size:16px">Familiarity with social media</span></li>
                               <li><span style="font-size:16px">Attention to detail</span></li>
                               <li><span style="font-size:16px">Good organizational and time-management skills</span></li>
                               <li><span style="font-size:16px">Ability to plan and create content relevant to a specific audience with a strong focus on SEO.</span></li>
                            </ul>
                            <p><span style="font-size:16px">Those who are interested can share their updated resume at <em><strong><a href="#">info@vmr.com</a></strong></em></span></p> -->
                            <div class="d-flex justify-content-center py-2" data-toggle="modal" data-target="#myModal">
                               <button (click)="getId(cr.id)" class="btn orange2 text-white btn-sm">Apply Now</button>
                            </div>
                            <!-- The Modal -->
                            <div class="modal fade" id="myModal">
                               <div class="modal-dialog">
                                  <div class="modal-content">
                                     <!-- Modal Header -->
                                     <div class="modal-header">
                                        <h4 class="modal-title blue1-color">Apply Now</h4>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                     </div>
                                     <!-- Modal body -->
                                     <div class="modal-body">
                                        <form #appForm = "ngForm" (ngSubmit)="insertApplication(formData)">
                                           <div class="form-group">
                                              <label for="name">Full Name</label>
                                              <input type="text" class="form-control" name="name" [(ngModel)]="formData.name" placeholder="Enter Name" id="name">
                                           </div>                                          
                                           <div class="form-group">
                                              <label for="email">Email Id</label>
                                              <input type="email" class="form-control" name="email" [(ngModel)]="formData.email" placeholder="Enter email" id="email">
                                           </div>
                                           <div class="form-group">
                                              <label for="phone">Mobile Number</label>
                                              <input type="number" class="form-control" name="phoneNumber" [(ngModel)]="formData.phoneNumber" placeholder="Enter Phone" id="phone">
                                           </div>
                                           <div class="form-group">
                                              <label for="file">Upload Resume(pdf only)</label>
                                              <input type="file" class="form-control" (change)="getResume($event)" id="file">
                                           </div>                                          
                                           <button type="submit" class="btn orange2 btn-sm text-white d-block mx-auto">Submit</button>
                                        </form>
                                     </div>
                                     <!-- Modal footer -->
                                     <div class="modal-footer">
                                        <!-- <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button> -->
                                     </div>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </section>
