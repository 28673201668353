import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { WebapiService } from '../services/webapi.service'; 

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  blogList:any;
  pageList: any;
  url: any;

  constructor(private router:ActivatedRoute,private WebapiService:WebapiService) { }

  ngOnInit(): void {

    
  //   this.router.queryParams.subscribe(params => {
  //     console.log(params); // { order: "popular" }

  //   }
  // );
  this.url = this.WebapiService.url1;

 this.getAllBlogs()
 this.getPage(5);
 
  }

  
  getAllBlogs(){
     this.WebapiService.getAllBlogs().subscribe((res: any) => {
     console.log(res);
     this.blogList = res; 
    });

  }

  getPage(id:any){
    this.WebapiService.getPage(id).subscribe((res: any) => {
      console.log(res);
       this.pageList = res;

});
}

}
