import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../services/webapi.service'; 

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {
  teamList:any=[];
  pageList: any;
  url:any;
  constructor(private WebapiService:WebapiService) { }

  ngOnInit(): void {
    this.getTeam();
    this.url = this.WebapiService.url1;
    this.getPage(4);
  }
  getTeam(){
 
        this.WebapiService.getTeam().subscribe((res: any) => {
    console.log(res);

      this.teamList = res;
    
    });
  }

  getPage(id:any){
    this.WebapiService.getPage(id).subscribe((res: any) => {
      console.log(res);
       this.pageList = res;

});
}

}
