import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../services/webapi.service'; 


@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit {
  pageList: any;
  url: any;
  carrerList: any;
  formData:any = {};
  appId:any;

  constructor(private WebapiService:WebapiService) { }

  ngOnInit(): void {
    this.url = this.WebapiService.url1;
    this.getPage(6);
    this.getCarrer();
  }

  getPage(id:any){
    this.WebapiService.getPage(id).subscribe((res: any) => {
      // console.log(res);
       this.pageList = res;

});
}

getCarrer(){
  this.WebapiService.getCarrer().subscribe((res: any) => {
    //  console.log(res);
     this.carrerList = res;
     
});
}

insertApplication(data:any){
//  console.log(data);
 this.WebapiService.insertApplication(data).subscribe((res: any) => {
  console.log(res);
  if(res.status == 1){
    alert(res.msg);
    location.reload();
  }
  else{
    alert(res.msg);
    location.reload();
  }
 });
 
}

getId(id:any){
 this.formData.appId = id;
}

getResume(e:any){
  this.formData.doc = e.target.files[0];
}

}
