import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CategoryComponent } from './category/category.component';
import { AboutComponent} from './about/about.component';
import { ActivateGuard } from './activate.guard';
import { CheckoutComponent } from './checkout/checkout.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { ProductdetailsComponent } from './productdetails/productdetails.component';
import { ProductsComponent } from './products/products.component';
import { TicketComponent } from './tickets/ticket/ticket.component';
import { BlogComponent } from './blog/blog.component';
import { BlogdetailsComponent } from './blogdetails/blogdetails.component';
import { TeamComponent } from './team/team.component';
import { TeamdetailsComponent } from './team/teamdetails/teamdetails.component';
import { CareerComponent } from './career/career.component';
import { IndustryComponent } from './industry/industry.component';
//import { TeamMemComponent } from './team-mem/team-mem.component';
import { IndustrydetailComponent } from './industry/industrydetail/industrydetail.component';
import { GetAQuoteComponent } from './get-a-quote/get-a-quote.component';
import { UserOrdersComponent } from './user-orders/user-orders.component';
import { UserInquiryComponent } from './user-inquiry/user-inquiry.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';



const routes: Routes = [
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent},
  { path: 'home', component: HomeComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'ticket', component: TicketComponent },
  { path: 'service/:id', component: ProductsComponent },
  { path: 'category', component: CategoryComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog-detail/:id', component: BlogdetailsComponent },
  { path: 'team', component: TeamComponent },
 { path: 'teamdetails', component: TeamdetailsComponent },
  { path: 'career', component: CareerComponent },
  { path: 'industry', component: IndustryComponent },
  { path: 'industrydetail/:id', component: IndustrydetailComponent },
  { path: 'get-a-quote', component: GetAQuoteComponent },
  { path: 'user-orders', component: UserOrdersComponent, canActivate:[ActivateGuard] },
  { path: 'user-inquiry', component: UserInquiryComponent, canActivate:[ActivateGuard] },
  { path: 'user-settings', component: UserSettingsComponent, canActivate:[ActivateGuard] },
  
  { path: '', component: HomeComponent },

  { path: '',   redirectTo: '/home', pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
