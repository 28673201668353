import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../services/webapi.service'; 
import { ActivatedRoute,Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  id = localStorage.getItem('loginId');
  categoryList:any;
  serviceList:any;
  locationList:any
  constructor(private WebapiService:WebapiService,private router:ActivatedRoute,private urouter:Router) { }

  ngOnInit(): void {
    // console.log(this.id);
    
    this.getCategory();
    this.getLocation();
   // console.log(this.categoryList);
    
  }

  getCategory(){

    this.WebapiService.getCategory().subscribe((res: any) => {
    //  console.log(res);
      this.categoryList = res;
    //for (let category of res) {
     this.getServices(this.categoryList);
   //   
    //}
      // this.total = res.totalRecords;
      // this.p = page;
      // this.loading = false;
      // this.categoryList = res.data;
    });
  }
  getServices(categoryList:any){
    //console.log(categoryList);
      for (let category of categoryList) {
        //console.log(category.id);
        
        this.WebapiService.getServices(category.id).subscribe((res: any) => {
     // console.log(res);
      category.service = res;
    
    });
  }
  }
  getServiceBySlug(id:any){

    const input  = {slug:id}
    //console.log(categoryList);
     // for (let category of categoryList) {
        //console.log(category.id);
        
        this.WebapiService.getServiceBySlug(input).subscribe((res: any) => {
        //  console.log(res);
           this.serviceList = res[0];
    
    });
  //}
  }
  getLocation(){
    //console.log(test);
    
        this.WebapiService.getLocation().subscribe((res: any) => {
         // console.log(res);
           this.locationList = res;
    
    });
  //}
  }
  logout(){
    localStorage.clear();
    alert("log out succesfull");
   location.reload();
  }
}
