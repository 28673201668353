import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { WebapiService } from '../services/webapi.service';
@Component({
  selector: 'app-user-inquiry',
  templateUrl: './user-inquiry.component.html',
  styleUrls: ['./user-inquiry.component.css']
})
export class UserInquiryComponent implements OnInit {
  enquiryList:any;
  constructor(private router:ActivatedRoute,private urouter:Router,private WebapiService:WebapiService,private notifyService : NotificationService) { }

  ngOnInit(): void {
    this.getEnquiryById(localStorage.getItem('loginId'));
  }

  getEnquiryById(id:any){
    // console.log(id);
    this.WebapiService.getEnquiryById(id).subscribe((res: any) => {
      console.log(res);
      this.enquiryList = res;
    });
  }
}
