<section class="" style="padding-bottom: 50px">
    <div class="aboutHeading">
       <div class="container">
          <div class="row">
             <div class="col-12 text-center">
                <h2 class="text-white">My Inquiry</h2>
                <ul class="breadcrumb">
                     <li><a href="/">Home</a></li>
                     <li><a href="#">My Inquiry</a></li>
                   </ul> 
             </div>
          </div>
       </div>
    </div>
 </section>
 <section class="aboutSlide"></section>
 <section class="section">
    <div style="margin-top: 3.5rem;">
       <div class="container">
          <div class="px-5">
          <div class="row">
              
             <div class="col-12 col-md-2">
                 <div class="sidebar">
                    <ul class="">
                        <li>
                            <a [routerLink]="['/user-orders']" routerLinkActive="active" ><img src=""> My Orders</a>
                        </li>
                        <li>
                            <a  [routerLink]="['/user-inquiry']" routerLinkActive="active" ><img src=""> My Inquiry</a>
                        </li>
                        <!-- <li>
                            <a  [routerLink]="['/user-settings']" routerLinkActive="active" ><img src=""> Settings</a>
                        </li> -->
                    </ul>
                </div>
            </div>
             <div class="col-12 col-md-9">
                <div class="order-wrap">
                    <table class="table">
                        <tr>
                            <th>Date</th>
                            <th>Services</th>
                        </tr>
                        <tr *ngFor="let enquiry of enquiryList">
                            <td>{{enquiry.date}}</td>
                            <td>{{enquiry.services}}</td>
                        </tr>
                    </table>
                </div>
               
               
             </div>
          </div>
          </div>
       </div>
    </div>
 </section>
 