import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { WebapiService } from '../services/webapi.service';
import { NotificationService } from '../services/notification.service'; 
@Component({
  selector: 'app-get-a-quote',
  templateUrl: './get-a-quote.component.html',
  styleUrls: ['./get-a-quote.component.css']
})
export class GetAQuoteComponent implements OnInit {
  serviceList:any;
  enquiryList:any;
  categoryList:any;
  getQuote:any={};
  services:any=[];
  locationList:any;
  constructor(private router:ActivatedRoute,private WebapiService:WebapiService,
    private urouter:Router,private notifyService : NotificationService) { }

  ngOnInit(): void {
    this.getAllServices();
    this.getCategory();
    this.getLocation();
    // console.log(this.selected);
  }
  getAllServices(){
  
        this.WebapiService.getAllServices().subscribe((res: any) => {
        //  console.log(res);
          this.enquiryList = res;
    
    });
  //}
  }
  submitQuote(){
    // for(let cat of this.categoryList){
    //   for(let service of cat.service){
    //     if(service.selected){
    //       this.services.push(service.id);
    //     }
    //   }
    // }
this.getQuote.services = this.services;
  // console.log(this.getQuote);
   // return;
    this.WebapiService.submitQuote(this.getQuote).subscribe((res: any) => {
       this.serviceList = res;
      // console.log(this.serviceList);
       if(this.serviceList.status==1){
        alert("submitted successfull");
        location.reload();
       }
       else{
        alert("error");
      }

});
//}
}
getCategory(){
  this.WebapiService.getCategory().subscribe((res: any) => {
  //  console.log(res);
    this.categoryList = res;
  //for (let category of res) {
   this.getServices(this.categoryList);

  });
}
getServices(categoryList:any){
  //console.log(categoryList);
    for (let category of categoryList) {
      //console.log(category.id);
      
      this.WebapiService.getServices(category.id).subscribe((res: any) => {
   // console.log(res);
   //category.service = [];
    category.service = res;
    for(let cat of category.service){
      cat.selected=false;
    }
    // category.services.forEach(cat => {
    //   cat.selected=false;
    // });

  });
}
} 
selectedService(id:any=[]){
   this.getQuote.services = id;
}
getLocation(){
  //console.log(test);
  
      this.WebapiService.getLocation().subscribe((res: any) => {
       // console.log(res);
         this.locationList = res;
  
  });
//}
}

}
