import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
//import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { CategoryComponent } from './category/category.component';
import { BlogComponent } from './blog/blog.component';
import { BlogdetailsComponent } from './blogdetails/blogdetails.component';
import { ProductsComponent } from './products/products.component';
import { ProductdetailsComponent } from './productdetails/productdetails.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { TicketComponent } from './tickets/ticket/ticket.component';
import { NavbarComponent } from './navbar/navbar.component';
//import { TeamComponent } from './team/team.component';
import { TeamdetailsComponent } from './team/teamdetails/teamdetails.component';
import { IndustryComponent } from './industry/industry.component';
import { IndustrydetailComponent } from './industry/industrydetail/industrydetail.component';
import { CareerComponent } from './career/career.component';
import { TeamComponent } from './team/team.component';
import { GetAQuoteComponent } from './get-a-quote/get-a-quote.component';
// import { IndustryDetailComponent } from './industry/industry-detail/industry-detail.component';
// import { ServiceComponent } from './service/service.component';
//import { GetAQuoteComponent } from './get-a-quote/get-a-quote.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { UserOrdersComponent } from './user-orders/user-orders.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { UserInquiryComponent } from './user-inquiry/user-inquiry.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    NgSelectModule
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    CategoryComponent,
    BlogComponent,
    BlogdetailsComponent,
    ProductsComponent,
    ProductdetailsComponent,
    HeaderComponent,
    FooterComponent,
    CartComponent,
    CheckoutComponent,
    AboutComponent,
    ContactComponent,
    TicketComponent,
    NavbarComponent,
    TeamComponent,
   TeamdetailsComponent,
    IndustryComponent,
    IndustrydetailComponent,
    CareerComponent,
    TeamComponent,
    GetAQuoteComponent,
    UserOrdersComponent,
    UserSettingsComponent,
    UserInquiryComponent,
    // IndustryDetailComponent
    // ServiceComponent
  ],
  
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }