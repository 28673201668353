
<div id="layoutSidenav_content">

    <div class="content container-fluid">
          <div class="page-header">
             <div class="row d-flex align-items-center">
                <div class="col">
                <h3 class="page-title">Ticket</h3>
                <ul class="breadcrumb p-0 bg-transparent">
                   <li class="breadcrumb-item"><a href="index.php" class="font-weight-bold text-dark">Dashboard</a></li>
                   <li class="breadcrumb-item active">Ticket</li>
                </ul>
             </div>
             <div class=" ml-auto">
                <a href="createticket.php" class=" btn btn-primary  bg-transparent text-success  border border-success">Create Ticket</a>
                <a href="ticketsettings.php" class=" btn btn-primary bg-transparent text-danger border border-danger"> Ticket Settings</a>
             </div>
             </div>
          </div>
    </div>

    <main>
       <div class="container-fluid">
          <div class="row">
             <div class="col-2 mx-auto">
                <div style="display: inline-block; width: 35px; height: 35px; border-radius: 25px;" class="bg-primary">
                   <p style="transform: translate(35%, 15%); color: #fff">7</p>
                </div>
             </div>
             <div class="col-2 mx-auto">
                <div style="display: inline-block; width: 35px; height: 35px; border-radius: 25px;" class="bg-secondary">
                   <p style="transform: translate(35%, 15%); color: #fff">0</p>
                </div>
             </div>
             <div class="col-2 mx-auto">
                <div style="display: inline-block; width: 35px; height: 35px; border-radius: 25px;" class="bg-success">
                   <p style="transform: translate(35%, 15%); color: #fff">7</p>
                </div>
             </div>
             <div class="col-2 mx-auto">
                <div style="display: inline-block; width: 35px; height: 35px; border-radius: 25px;" class="bg-danger">
                   <p style="transform: translate(35%, 15%); color: #fff">0</p>
                </div>
             </div>
             <div class="col-2 mx-auto">
                <div style="display: inline-block; width: 35px; height: 35px; border-radius: 25px;" class="bg-warning">
                   <p style="transform: translate(35%, 15%); color: #fff">0</p>
                </div>
             </div>
          </div>
          <div class="card mb-4 mt-4">
             <div class="card-body">
                <div class="table-responsive">
                   <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                      <thead>
                         <tr>
                            <th>#</th>
                            <th class="text-uppercase">Ticket Subject</th>
                            <th class="text-uppercase">Requester Name</th>
                            <th class="text-uppercase">Requested On</th>
                            <th class="text-uppercase">Others</th>
                            <th class="text-uppercase">Action</th>
                         </tr>
                      </thead>
                      <tbody>
                         <tr>
                            <td>1</td>
                            <td>System Architect</td>
                            <td>Edinburgh</td>
                            <td>61</td>
                            <td>2011/04/25</td>
                            <td>$320,800</td>
                         </tr>
                         <tr>
                            <td>2</td>
                            <td>Accountant</td>
                            <td>Tokyo</td>
                            <td>63</td>
                            <td>2011/07/25</td>
                            <td>$170,750</td>
                         </tr>
                         <tr>
                            <td>3</td>
                            <td>Junior Technical Author</td>
                            <td>San Francisco</td>
                            <td>66</td>
                            <td>2009/01/12</td>
                            <td>$86,000</td>
                         </tr>
                         <tr>
                            <td>4</td>
                            <td>Senior Javascript Developer</td>
                            <td>Edinburgh</td>
                            <td>22</td>
                            <td>2012/03/29</td>
                            <td>$433,060</td>
                         </tr>
                         <tr>
                            <td>5</td>
                            <td>Accountant</td>
                            <td>Tokyo</td>
                            <td>33</td>
                            <td>2008/11/28</td>
                            <td>$162,700</td>
                         </tr>
                         <tr>
                            <td>6</td>
                            <td>Integration Specialist</td>
                            <td>New York</td>
                            <td>61</td>
                            <td>2012/12/02</td>
                            <td>$372,000</td>
                         </tr>
                         <tr>
                            <td>7</td>
                            <td>Sales Assistant</td>
                            <td>San Francisco</td>
                            <td>59</td>
                            <td>2012/08/06</td>
                            <td>$137,500</td>
                         </tr>
                         <tr>
                            <td>8</td>
                            <td>Integration Specialist</td>
                            <td>Tokyo</td>
                            <td>55</td>
                            <td>2010/10/14</td>
                            <td>$327,900</td>
                         </tr>
                         <tr>
                            <td>9</td>
                            <td>Javascript Developer</td>
                            <td>San Francisco</td>
                            <td>39</td>
                            <td>2009/09/15</td>
                            <td>$205,500</td>
                         </tr>
                      </tbody>
                   </table>
                </div>
             </div>
          </div>
  
 
 </div>
