
<style type="text/css">
  /*SIGN IN */
  :root {
  /* --input-padding-x: 1.5rem; */
  /* --input-padding-y: .75rem; */
}

/*body {
  background: #007bff;
  background: linear-gradient(to right, #0062E6, #33AEFF);
}*/
#myTab .nav-tabs .nav-link.active{
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
#myTab .nav-item a:not(.active){
  /*background-color: #777;*/
  background-color: lightgray;
color: gray;
font-weight: 600;
}
.w-44{
  width: 44%;
}
.font-75{
  font-size: .75rem !important;
}
.font-85{
  font-size: .85rem;
}
.modal-content{
  background: transparent;
  border: none;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  /*color: #007bff;*/
  color: #b65f36;
  font-weight: 600;
}
a{
  color: #000;
}
.card-signin {
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}
.form-label-group input::placeholder {
    color: #495057 !important;
}
.card-signin .card-title {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.card-signin .card-body {
  padding: 2rem;
}

.form-signin {
  width: 100%;
}

.form-signin .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: .1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input {
  height: auto;
  border-radius: 2rem;
}

.form-label-group>input,
.form-label-group>label {
  /* padding: var(--input-padding-y) var(--input-padding-x); */
}

.form-label-group>label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown)~label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

.btn-google {
  color: white;
  background-color: #ea4335;
}

.btn-facebook {
  color: white;
  background-color: #3b5998;
}

/* Fallback for Edge
-------------------------------------------------- */

@supports (-ms-ime-align: auto) {
  .form-label-group>label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .form-label-group>label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}

</style>


<div class="modal fade" id="loginmodel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
<ul class="nav nav-tabs justify-content-center border-0" id="myTab" role="tablist">
    <li class="nav-item w-44">
    <a class="nav-link active text-center font-85 border-0" id="login-tab" data-toggle="tab" href="#login" role="tab" aria-controls="login" aria-selected="true">LOGIN</a>
    </li>
    <li class="nav-item w-44">
    <a class="nav-link text-center font-85 border-0" id="register-tab" data-toggle="tab" href="#register" role="tab" aria-controls="register" aria-selected="false">REGISTER</a>
    </li>
</ul>
<div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="login" role="tabpanel" aria-labelledby="login-tab">
      
      <!-- LOG IN FORM -->
      <div class="container">
    <div class="row">
      <div class="col-sm-9 col-md-12 col-lg-12 mx-auto">
        <div class="card card-signin">
          <div class="card-body">
            <h5 class="card-title text-center">Sign In</h5>
            <form #loginForm = "ngForm" class="form-signin" (ngSubmit)="onLogin(formData)">
              <div class="form-label-group">
                <input type="email" id="inputEmail" name="email" class="form-control" [(ngModel)] = "formData.email" placeholder="Email address" required autofocus>
                <!-- <label for="inputEmail">Email address</label> -->
              </div>
              <div class="form-label-group">
                <input type="password" id="inputPassword" class="form-control" name="password" [(ngModel)] = "formData.password" placeholder="Password" required>
                <!-- <label for="inputPassword">Password</label> -->
              </div>

              <div class="custom-control custom-checkbox mb-3">
                <input type="checkbox" class="custom-control-input" id="customCheck1">
                <label class="custom-control-label" for="customCheck1"><span class="font-85">Remember password
                   
                </span></label>
              </div>
              <button class="btn btn-lg green-col btn-block text-uppercase text-white font-75" type="submit">Sign in</button>
              <!-- <hr class="my-4">
              <div class="text-center">
                <h6>Or</h6>
              <a href="#" class="mr-1"><img src="assets/fb.png" alt="fb" class="img-fluid"></a>
              <a href="#" class="ml-1"><img src="assets/gsearch.png" alt="fb" class="img-fluid"></a>
              </div> -->
              
              <!-- <button class="btn btn-lg btn-google btn-block text-uppercase" type="submit"><i class="fab fa-google mr-2"></i> Sign in with Google</button>
              <button class="btn btn-lg btn-facebook btn-block text-uppercase" type="submit"><i class="fab fa-facebook-f mr-2"></i> Sign in with Facebook</button> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
    <!-- LOG IN FORM END -->

    <div class="tab-pane fade" id="register" role="tabpanel" aria-labelledby="register-tab">
        <!-- REGISTER START -->
        <div class="container">
    <div class="row">
      <div class="col-lg-12 col-xl-12 mx-auto">
        <div class="card card-signin flex-row">
          <div class="card-img-left d-none d-md-flex">
             <!-- Background image for card set in CSS! -->
          </div>
          <div class="card-body">
            <h5 class="card-title text-center">Register</h5>
            <form class="form-signin" #regForm = "ngForm" (ngSubmit)="regForm.valid && onReg(regData)">
              <div class="form-label-group">
                <input type="text" id="inputUserame" name="uname" [(ngModel)]="regData.name" class="form-control" #name="ngModel" placeholder="Name" required>
                <span class="text-danger"
                                *ngIf="(name.touched || regForm.submitted) && name.errors?.required">
                                *Name is required
                            </span>
                <!-- <label for="inputUserame">Username</label> -->
              </div>

              <div class="form-label-group">
                <input type="email" id="inputEmail"name="email" class="form-control" #email="ngModel" [(ngModel)]="regData.email" placeholder="Email address" required email>
                <span class="text-danger"
                                *ngIf="(email.touched || regForm.submitted) && email.errors?.required">
                                *Email is required
                            </span>
                            <span class="text-danger" *ngIf="email.touched && email.errors?.email">
                                *Enter a valid email address
                            </span>
                <!-- <label for="inputEmail">Email address</label> -->
              </div>
              
              <!-- <hr> -->

              <div class="form-label-group">
                <input type="password" name="pass" #pass="ngModel" [(ngModel)]="regData.pass" id="inputPassword" class="form-control" placeholder="Password" required>
                <span class="text-danger"
                *ngIf="(pass.touched || regForm.submitted) && pass.errors?.required">
                *Password is required
            </span>
                <!-- <label for="inputPassword">Password</label> -->
              </div>
              
              <!-- <div class="form-label-group">
                <input type="password" id="inputConfirmPassword" name="cpass" #cpass="ngModel" [(ngModel)]="regData.cpass" class="form-control" placeholder="Confirm Password" required> -->
                <!-- <label for="inputConfirmPassword">Confirm password</label> -->
              <!-- </div> -->

              <button  class="btn btn-lg green-col text-white btn-block text-uppercase font-75"  type="submit">Register</button>
              <!-- <a class="d-block text-center mt-2 small" href="#">Sign In</a> -->
              <!-- <hr class="my-4">
              <div class="text-center">
                <h6>Or</h6>
              <a href="#" class="mr-1"><img src="assets/fb.png" alt="fb" class="img-fluid"></a>
              <a href="#" class="ml-1"><img src="assets/gsearch.png" alt="fb" class="img-fluid"></a>
              </div> -->
              <!-- <button class="btn btn-lg btn-google btn-block text-uppercase" type="submit"><i class="fab fa-google mr-2"></i> Sign up with Google</button>
              <button class="btn btn-lg btn-facebook btn-block text-uppercase" type="submit"><i class="fab fa-facebook-f mr-2"></i> Sign up with Facebook</button> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
        <!-- REGISTER END -->
    </div>
</div>
</div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>


<footer class="footer text-white blue1">
    <div class="container">
       <div class="row py-5">
          <div class="col-md-3 col-12" style="position: relative; z-index: 3">
             <p class="text-justify small">We are team of professionals like Chartered Accountants, Company Secretaries, Cost Accountants, Lawyers and Technical Consultants of industry specific having expertise in their profession and field  providing services to various clients from various industries. </p>
          </div>
          <div class="col-md-3 col-sm-4 col-6  py-2" style="position: relative; z-index: 3">
             Services
             <ul class="navbar-nav small">
                <li class="nav-item "  *ngFor="let data of categoryList">
                   <a class="nav-link text-white"  routerLink="/category" [queryParams]="{'slug':data.slug}"><i class="fas fa-caret-right"></i> {{data.category_name}}</a>
                </li>
               
             </ul>
          </div>
          <div class="col-md-3 col-sm-4 col-6  py-2" style="position: relative; z-index: 3">
             Useful Links
             <ul class="navbar-nav small">
                <li class="nav-item active">
                   <a class="nav-link text-white" routerLink = "/"><i class="fas fa-caret-right"></i> Home</a>
                </li>
                <li class="nav-item">
                   <a class="nav-link text-white" routerLink = "/about"><i class="fas fa-caret-right"></i> About Us</a>
                </li>
                
                <li class="nav-item">
                   <a class="nav-link text-white" routerLink = "/contact"><i class="fas fa-caret-right"></i> Contact Us</a>
                </li>
             </ul>
          </div>
          <div class="col-md-3 col-sm-4 col-12 " style="position: relative; z-index: 3">
             Reach Us
             <p class="small">Address:<a href="https://goo.gl/maps/C7YLApPmWUbv5qew6" class="white" target="_blank"> 712-A, West-End Mall, Janakpuri District Center, Janakpuri, New Delhi, Delhi 110058<br><br></a>
                Phone No:<br> +91-7428243651, <br>+91-8340474267 <br><br>
                Email: info@vmradvisors.com
             </p>
          </div>
          <div class="col-12 col-md-6 text-center text-sm-center text-md-left" style="position: relative; z-index: 3">
             <p class="small py-4">2022 Copyright. All Rights Reserved. VMRADVISORS.</p>
          </div>
 <!--          <div class="c1"></div>
          <div class="c2"></div> -->
       </div>
    </div>
 </footer>