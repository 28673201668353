import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../services/webapi.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  pageList: any;
  url: any;

  constructor(private WebapiService:WebapiService) { }

  ngOnInit(): void {
    this.url = this.WebapiService.url1;
    this.getPage(7);
  }

  getPage(id:any){
    this.WebapiService.getPage(id).subscribe((res: any) => {
      console.log(res);
       this.pageList = res;

});
}
}
