import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../../services/webapi.service'; 
import { ActivatedRoute,Router } from '@angular/router';
@Component({
  selector: 'app-industrydetail',
  templateUrl: './industrydetail.component.html',
  styleUrls: ['./industrydetail.component.css']
})
export class IndustrydetailComponent implements OnInit {

   blogList:any
  slug:any;
  categoryList:any;
  getIndustryList:any={};
  inquery:any={}
  constructor(private router:ActivatedRoute,
    private WebapiService:WebapiService,
    private urouter:Router) { }

  ngOnInit(): void {

    let pths= this.urouter.url.split('/');
   // console.log(pths[1]);
   // console.log(this.urouter.url);
    this.router.queryParams.subscribe(params => {
      //  console.log(params); // { order: "popular" 
      this.slug = params.slug;
       // console.log(this.order); // popular
      }
    );

    this.getIndustryBySlug(pths[2]);
    //console.log(this.categoryList);
    
  }


  getIndustryBySlug(id:any){
        this.WebapiService.getIndustryBySlug(id).subscribe((res: any) => {
          console.log(res);
           this.getIndustryList = res[0];
           this.getBlogsByIndustryId(res[0].id);
    
    });
  //}
  }
  insertInquery(){
    this.inquery.service_id =   this.getIndustryList.id;
    console.log(this.inquery);
    this.WebapiService.insertInquery(this.inquery).subscribe((res: any) => {
     alert(res.msg);
      console.log(res);
       //this.serviceList = res[0];

});
  }
  getBlogsByIndustryId(id:any){
    this.WebapiService.getBlogsByIndustryId(id).subscribe((res: any) => {
      console.log(res);
     this.blogList = res;
    });
  }
}
