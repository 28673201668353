import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../services/webapi.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  constructor(private webApi:WebapiService) { }

  ngOnInit(): void {
  }
   options = {
    "key": "rzp_live_UCFuJje6YfI4eO", // Enter the Key ID generated from the Dashboard
    "amount": "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    "currency": "INR",
    "name": "Acme Corp",
    "description": "Test Transaction",
    "image": "https://example.com/your_logo",
    "order_id": "", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
    "prefill": {
        "name": "Gaurav Kumar",
        "email": "gaurav.kumar@example.com",
        "contact": "9999999999"
    },
    "notes": {
        "address": "Razorpay Corporate Office"
    },
    "theme": {
        "color": "#3399cc"
    }
  }
//    rzp1 = new Razorpay(options);
// document.getElementById('rzp-button1').onclick = function(e){
//     rzp1.open();
//     e.preventDefault();
// }
rzp1:any;
  pay(){

    this.rzp1 = new this.webApi.nativeWindow.Razorpay(this.options);
// document.getElementById('rzp-button1').onclick = function(e){
    this.rzp1.open();
//     e.preventDefault();
// }
  }
}
