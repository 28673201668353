<style>
 
.pull-left{
   float: left;
}
</style>

<section class="" style="padding-bottom: 50px">
    <div class="aboutHeading">
       <div class="container">
          <div class="row">
             <div class="col-12 text-center">
                <h2 class="text-white">GET A QUOTE</h2>
                <ul class="breadcrumb">
                   <li><a href="">Home</a></li>
                   <li><a href="#">GET A QUOTE</a></li>
                </ul>
             </div>
          </div>
       </div>
    </div>
 </section>
 <!-- <section class="aboutSlide"></section> -->

 <section class="section">
    <div class="whyVMR" style="margin-bottom: 5rem;">
       <div class="container">
          <div class="px-5">
             <div class="row justify-content-center">
                <div class="col-lg-8 get_a_quote_form">
             <form class="">
                 <div class="form-group">
                    <h4>Select Services for enquiry?</h4>
                    <!-- <div class="col-lg-12 p-0" *ngFor="let cat of categoryList">
                       <h5 class="cat" >{{cat.category_name}}</h5>
                       
                       <label class="col-lg-4"  *ngFor="let data of cat.service">
                       <input type="checkbox" [(ngModel)]="data.selected" [ngModelOptions]="{standalone: true}"> {{data.service_name}}
                       </label>
                     </div> -->
                     <ng-select [multiple]="true" (change)="selectedService(services)" name="services[]" [(ngModel)]="services" [ngModelOptions]="{standalone: true}">
                        <ng-option *ngFor="let data of enquiryList" [value]="data.id">{{data.service_name}}</ng-option>  
                    </ng-select>
                 
                </div> <!--  form-group -->
                <div class="col-lg-12 p-0">
                <div class="col-lg-6 pull-left">
                  <h5>Your Name</h5>
                  <div class="form-group">
                     <input type="text" class="form-control" name="name" [ngModelOptions]="{standalone: true}" [(ngModel)]="getQuote.name" placeholder="" required="">
                  </div>
               </div>
               <div class="col-lg-6 pull-left">
                  <h5>Company Name</h5>
                  <div class="form-group">
                     <input type="text" class="form-control" name=""  [ngModelOptions]="{standalone: true}" [(ngModel)]="getQuote.company_name"  placeholder="" required>
                  </div>
               </div>
               
               <div class="col-lg-6 pull-left">
                  <h5>Email id</h5>
                  <div class="form-group">
                     <input type="text" class="form-control" name="email"  [ngModelOptions]="{standalone: true}" [(ngModel)]="getQuote.email"  placeholder="" required>
                  </div>
               </div>
               
               <div class="col-lg-6 pull-left">
                  <h5>Phone No</h5>
                  <div class="form-group">
                     <input type="text" class="form-control" name="phone"  [ngModelOptions]="{standalone: true}" [(ngModel)]="getQuote.phone"  placeholder="" required>
                  </div>
               </div>
               
               <div class="col-lg-6 pull-left">
                  <h5>Location</h5>
                  <div class="form-group">
                     <select class="form-control" >
                        <option disabled selected>LOCATION</option>
                        <option *ngFor="let data of locationList">{{data.location_name}}</option>
                      
                     </select>
                  </div>
               </div>
               <div class="form-group  col-lg-12 pull-left">
                  <!-- <h5>Phone No</h5> -->
                  <div class="form-group">
                     <input type="submit" class="btn btn-warning white" (click)="submitQuote();" placeholder="">
                  </div>
               </div>
            </div>
             </form>
            </div>
                
                                                  
             </div>
          </div>
       </div>
    </div>
 </section>