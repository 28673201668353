import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { WebapiService } from '../services/webapi.service';

@Component({
  selector: 'app-user-orders',
  templateUrl: './user-orders.component.html',
  styleUrls: ['./user-orders.component.css']
})
export class UserOrdersComponent implements OnInit {
  orderList:any;
  inputValue:any;
  formData:any;
  files:any;
  constructor(private router:ActivatedRoute,private urouter:Router,private WebapiService:WebapiService,private notifyService : NotificationService) { }

  ngOnInit(): void {
    this.getOrderById(localStorage.getItem('loginId'));
  }
  getOrderById(id:any){
    // console.log(id);
    this.WebapiService.getOrderById(id).subscribe((res: any) => {
      console.log(res);
      this.orderList = res;
    });
  }
 
  onFileChange(e:any,name:any){
    // console.log(e.target.files);
    this.inputValue = {
      "image": e.target.files[0],
      "type":"return",
      "userId" : localStorage.getItem('loginId'),
      "serviceName": name
    };
  }
  onUpload(){
    this.WebapiService.insertDoc(this.inputValue).subscribe((res: any) => {
      // console.log(res.status);
      if(res.msg == 1){
        alert("uploaded");
        location.reload();
      }
      else{
        alert("something went wrong");
      }
     });
  }

}