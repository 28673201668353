<section class="" style="padding-bottom: 50px">
    <div class="aboutHeading" *ngFor="let p of pageList"   style="background-image: url('{{url}}images/page_image/{{p.image}}') ;">
       <div class="container">
          <div class="row">
             <div class="col-12 text-center">
                <h2 class="text-white">{{p.title}}</h2>
                <h6 class="">{{p.content}}</h6>
                       <!-- <img src="{{url}}images/page_image/{{p.image}}" alt="Industry page"> -->
                <ul class="breadcrumb">
                   <li><a href="/">Home</a></li>
                   <li><a href="#">Industry</a></li>
                </ul>
             </div>
          </div>
       </div>
    </div>
 </section>
 <section class="aboutSlide"></section>
 <!-- <section class="section">
    <div style="margin-top: 3.5rem;">
       <div class="container-fluid">
          <div class="px-5">
          <div class="row">
             <div class="col-12 col-md-6">
                <div style="background: linear-gradient(#3f96fd 0%,#3999ff 100%);width: 150px;height: 5px;margin-bottom: 10px;">
                </div>
                <h2 class="mb-4"><b>We are</b> prominent Chartered Accountant in India</h2>
                <p class="mb-5 text-justify">
                   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tristique nisl purus, et porta orci sagittis eget. Aliquam auctor elit in massa lacinia, sit amet cursus arcu lobortis. Nunc vel elementum justo, faucibus dapibus magna. Integer vel tristique tortor. Etiam tincidunt accumsan tellus. Curabitur vitae lorem venenatis, lobortis lectus a, pulvinar augue. Vivamus tristique euismod lorem non luctus. Ut at tellus in purus tincidunt efficitur. 
                   <br><br>
                   Nulla sed dignissim magna. Sed eros tortor, viverra nec dui at, viverra volutpat elit. Integer a dui odio. Nullam nisl elit, bibendum quis mattis at, facilisis nec neque. In at malesuada erat. Mauris in facilisis.Nulla sed dignissim magna. Sed eros tortor, viverra nec dui at, viverra volutpat elit. Integer a dui odio. Nullam nisl elit, bibendum quis mattis at, facilisis nec neque. In at malesuada erat. Mauris in facilisis.Nulla sed dignissim magna. Sed eros tortor, viverra nec dui at, viverra volutpat elit. Integer a dui odio. Nullam nisl elit, bibendum quis mattis at, facilisis nec neque. In at malesuada erat. Mauris in facilisis. 
                </p>
                <a href="#" class="btn text-border border-primary">
                Meet Our Team</a>
             </div>
             <div class="col-12 col-md-6">
                <img src="img/groupDiscuss.svg" class="img-fluid">
                <img src="img/undraw_Calculator_0evy.svg" class="img-fluid">
             </div>
          </div>
          </div>
       </div>
    </div>
    </section> -->
 <section class="section">
    <div class="whyVMR" style="margin-bottom: 5rem;">
       <div class="container">
          <div class="px-5">
             <div class="row">
                <div class="col-12 text-center">
                   <!-- <div class="mx-auto" style="background: linear-gradient(#3f96fd 0%,#3999ff 100%);width: 150px;height: 5px;margin-bottom: 20px;">
                   </div> -->
                   <!-- <h2 class="mb-4"><b>INDUSTRY</b></h2> -->
                   <p class="mb-2 mx-auto">Our team is dedicated to deliver best services in all the industries. We have expertise in working with a vast range of industries. </p>
                </div>
                <div class="col-12 col-md-6 col-lg-3 text-center pt-4 px-2" *ngFor="let data of getIndustryList">
                   <div class="text-dark bg-white py-4 px-4 industry" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                      <div style="display: inline-block;position: relative;">
                   
                         <i class="lni lni-ambulance" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                        </div>
                        <!-- routerLink="/industrydetail" [queryParams]="{'id':data.id}" -->
                      <a routerLink="/industrydetail/{{data.slug}}"  routerLinkActive="active" >   <h6 class="mt-3 my-4">{{data.name}}</h6></a>
                                    </div>
                </div>


                <div class="col-12 col-md-6 col-lg-3 text-center pt-4 px-2">
                   <div class="text-dark bg-white py-4 px-4 industry" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                      <div style="display: inline-block;position: relative;">
                         <!-- <img src="img/services-shape.svg" class="shape1">
                         <img src="img/services-shape-2.svg" class="shape2"> -->
                         <i class="lni lni-video " style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                         <!-- <img src="img/star.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                      </div>
                      <h6 class="mt-3 my-4">Media & Entertainment</h6>
                      <!-- <p class="mb-4">We use our skills gained over a period of time to help your work to be smarter, quicker & focused.</p> -->
                      <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                   </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 text-center pt-4 px-2 ">
                   <div class="text-dark bg-white py-4 px-4 industry" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                      <div style="display: inline-block;position: relative;">
                         <!-- <img src="img/services-shape.svg" class="shape1">
                         <img src="img/services-shape-2.svg" class="shape2"> -->
                         <i class="lni lni-investment " style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                         <!-- <img src="img/info.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                      </div>
                      <h6 class="mt-3 my-4">Financial Services</h6>
                      <!-- <p class="mb-4">We use our skills gained over a period of time to help your work to be smarter, quicker & focused.</p> -->
                      <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                   </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 text-center pt-4 px-2 ">
                   <div class="text-dark bg-white py-4 px-4 industry" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                      <div style="display: inline-block;position: relative;">
                         <!-- <img src="img/services-shape.svg" class="shape1">
                         <img src="img/services-shape-2.svg" class="shape2"> -->
                         <i class="lni lni-capsule " style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                         <!-- <img src="img/edit-list.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                      </div>
                      <h6 class="mt-3 my-4">Pharmaceuticals & Life Sciences</h6>
                      <!-- <p class="mb-4">We use our skills gained over a period of time to help your work to be smarter, quicker & focused.</p> -->
                      <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                   </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 text-center pt-4 px-2 ">
                   <div class="text-dark bg-white py-4 px-4 industry" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                      <div style="display: inline-block;position: relative;">
                         <!-- <img src="img/services-shape.svg" class="shape1">
                         <img src="img/services-shape-2.svg" class="shape2"> -->
                         <i class="lni lni-invest-monitor " style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                         <!-- <img src="img/edit-list.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                      </div>
                      <h6 class="mt-3 my-4">Private Equity</h6>
                      <!-- <p class="mb-4">We use our skills gained over a period of time to help your work to be smarter, quicker & focused.</p> -->
                      <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                   </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 text-center pt-4 px-2 ">
                   <div class="text-dark bg-white py-4 px-4 industry" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                      <div style="display: inline-block;position: relative;">
                         <!-- <img src="img/services-shape.svg" class="shape1">
                         <img src="img/services-shape-2.svg" class="shape2"> -->
                         <i class="lni lni-package " style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                         <!-- <img src="img/edit-list.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                      </div>
                      <h6 class="mt-3 my-4">Industrial Manufacturing</h6>
                      <!-- <p class="mb-4">We use our skills gained over a period of time to help your work to be smarter, quicker & focused.</p> -->
                      <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                   </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 text-center pt-4 px-2 ">
                   <div class="text-dark bg-white py-4 px-4 industry" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                      <div style="display: inline-block;position: relative;">
                         <!-- <img src="img/services-shape.svg" class="shape1">
                         <img src="img/services-shape-2.svg" class="shape2"> -->
                         <i class="lni lni-code-alt " style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                         <!-- <img src="img/edit-list.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                      </div>
                      <h6 class="mt-3 my-4">Information & Technology</h6>
                      <!-- <p class="mb-4">We use our skills gained over a period of time to help your work to be smarter, quicker & focused.</p> -->
                      <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                   </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 text-center pt-4 px-2 ">
                   <div class="text-dark bg-white py-4 px-4 industry" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                      <div style="display: inline-block;position: relative;">
                         <!-- <img src="img/services-shape.svg" class="shape1">
                         <img src="img/services-shape-2.svg" class="shape2"> -->
                         <i class="lni lni-code " style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                         <!-- <img src="img/edit-list.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                      </div>
                      <h6 class="mt-3 my-4">Blockchain Technology</h6>
                      <!-- <p class="mb-4">We use our skills gained over a period of time to help your work to be smarter, quicker & focused.</p> -->
                      <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                   </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 text-center pt-4 px-2 ">
                   <div class="text-dark bg-white py-4 px-4 industry" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                      <div style="display: inline-block;position: relative;">
                         <!-- <img src="img/services-shape.svg" class="shape1">
                         <img src="img/services-shape-2.svg" class="shape2"> -->
                         <i class="lni lni-phone-set " style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                         <!-- <img src="img/edit-list.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                      </div>
                      <h6 class="mt-3 my-4">Telecom</h6>
                      <!-- <p class="mb-4">We use our skills gained over a period of time to help your work to be smarter, quicker & focused.</p> -->
                      <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                   </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 text-center pt-4 px-2 ">
                   <div class="text-dark bg-white py-4 px-4 industry" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                      <div style="display: inline-block;position: relative;">
                         <!-- <img src="img/services-shape.svg" class="shape1">
                         <img src="img/services-shape-2.svg" class="shape2"> -->
                         <i class="lni lni-graduation " style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                         <!-- <img src="img/edit-list.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                      </div>
                      <h6 class="mt-3 my-4">Education</h6>
                      <!-- <p class="mb-4">We use our skills gained over a period of time to help your work to be smarter, quicker & focused.</p> -->
                      <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                   </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 text-center pt-4 px-2 ">
                   <div class="text-dark bg-white py-4 px-4 industry" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                      <div style="display: inline-block;position: relative;">
                         <!-- <img src="img/services-shape.svg" class="shape1">
                         <img src="img/services-shape-2.svg" class="shape2"> -->
                         <i class="lni lni-apartment " style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                         <!-- <img src="img/edit-list.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                      </div>
                      <h6 class="mt-3 my-4">Real Estate & Infrastructure</h6>
                      <!-- <p class="mb-4">We use our skills gained over a period of time to help your work to be smarter, quicker & focused.</p> -->
                      <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                   </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 text-center pt-4 px-2 ">
                   <div class="text-dark bg-white py-4 px-4 industry" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                      <div style="display: inline-block;position: relative;">
                         <!-- <img src="img/services-shape.svg" class="shape1">
                         <img src="img/services-shape-2.svg" class="shape2"> -->
                         <i class="lni lni-car-alt " style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                         <!-- <img src="img/edit-list.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                      </div>
                      <h6 class="mt-3 my-4">Automotive</h6>
                      <!-- <p class="mb-4">We use our skills gained over a period of time to help your work to be smarter, quicker & focused.</p> -->
                      <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                   </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 text-center pt-4 px-2 ">
                   <div class="text-dark bg-white py-4 px-4 industry" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                      <div style="display: inline-block;position: relative;">
                         <!-- <img src="img/services-shape.svg" class="shape1">
                         <img src="img/services-shape-2.svg" class="shape2"> -->
                         <i class="lni lni-invention " style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                         <!-- <img src="img/edit-list.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                      </div>
                      <h6 class="mt-3 my-4">Power & Mining</h6>
                      <!-- <p class="mb-4">We use our skills gained over a period of time to help your work to be smarter, quicker & focused.</p> -->
                      <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                   </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 text-center pt-4 px-2 ">
                   <div class="text-dark bg-white py-4 px-4 industry" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                      <div style="display: inline-block;position: relative;">
                         <!-- <img src="img/services-shape.svg" class="shape1">
                         <img src="img/services-shape-2.svg" class="shape2"> -->
                         <i class="lni lni-investment " style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                         <!-- <img src="img/edit-list.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                      </div>
                      <h6 class="mt-3 my-4">Government & Public Sector</h6>
                      <!-- <p class="mb-4">We use our skills gained over a period of time to help your work to be smarter, quicker & focused.</p> -->
                      <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                   </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 text-center pt-4 px-2 ">
                   <div class="text-dark bg-white py-4 px-4 industry" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                      <div style="display: inline-block;position: relative;">
                         <!-- <img src="img/services-shape.svg" class="shape1">
                         <img src="img/services-shape-2.svg" class="shape2"> -->
                         <i class="lni lni-investment " style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                         <!-- <img src="img/edit-list.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                      </div>
                      <h6 class="mt-3 my-4">Chemicals</h6>
                      <!-- <p class="mb-4">We use our skills gained over a period of time to help your work to be smarter, quicker & focused.</p> -->
                      <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                   </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 text-center pt-4 px-2 ">
                   <div class="text-dark bg-white py-4 px-4 industry" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                      <div style="display: inline-block;position: relative;">
                         <!-- <img src="img/services-shape.svg" class="shape1">
                         <img src="img/services-shape-2.svg" class="shape2"> -->
                         <i class="lni lni-investment " style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                         <!-- <img src="img/edit-list.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                      </div>
                      <h6 class="mt-3 my-4">Metals</h6>
                      <!-- <p class="mb-4">We use our skills gained over a period of time to help your work to be smarter, quicker & focused.</p> -->
                      <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                   </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 text-center pt-4 px-2 ml-auto">
                   <div class="text-dark bg-white py-4 px-4 industry" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                      <div style="display: inline-block;position: relative;">
                         <!-- <img src="img/services-shape.svg" class="shape1">
                         <img src="img/services-shape-2.svg" class="shape2"> -->
                         <i class="lni lni-investment " style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                         <!-- <img src="img/edit-list.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                      </div>
                      <h6 class="mt-3 my-4">Oil & Gas</h6>
                      <!-- <p class="mb-4">We use our skills gained over a period of time to help your work to be smarter, quicker & focused.</p> -->
                      <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                   </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 text-center pt-4 px-2 mr-auto">
                   <div class="text-dark bg-white py-4 px-4 industry" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                      <div style="display: inline-block;position: relative;">
                         <!-- <img src="img/services-shape.svg" class="shape1">
                         <img src="img/services-shape-2.svg" class="shape2"> -->
                         <i class="lni lni-investment " style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                         <!-- <img src="img/edit-list.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                      </div>
                      <h6 class="mt-3 my-4">Retail & Consumer</h6>
                      <!-- <p class="mb-4">We use our skills gained over a period of time to help your work to be smarter, quicker & focused.</p> -->
                      <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                   </div>
                </div>                                                                                                                                          
             </div>
          </div>
       </div>
    </div>
 </section>