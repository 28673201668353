import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../services/webapi.service'; 

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  pageList: any;
  url:any

  constructor(private WebapiService:WebapiService) { }

  ngOnInit(): void {
    this.getPage(1);
    this.url = this.WebapiService.url1;
  }

  getPage(id:any){
         this.WebapiService.getPage(id).subscribe((res: any) => {
           console.log(res);
            this.pageList = res;
     
     });
   }

}
