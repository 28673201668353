import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { WebapiService } from './services/webapi.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ActivateGuard implements CanActivate {
   constructor(private router: Router,
    private service:WebapiService,
    private toastr: ToastrService
    ){

    }
    canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     //return true;
      if(this.service.isLogedIn()){
        return true;
      //  alert(this.service.isLogedIn());
      }else{
        this.toastr.error("Please login first");
         this.router.navigate(['/']);
        return false;
      }
    }
  
}
