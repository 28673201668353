import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../../services/webapi.service'; 
import { ActivatedRoute,Router } from '@angular/router';
@Component({
  selector: 'app-teamdetails',
  templateUrl: './teamdetails.component.html',
  styleUrls: ['./teamdetails.component.css']
})
export class TeamdetailsComponent implements OnInit {

  teamList:any={};
  teamListall:any={};
  id:any;
  teamId: any;
  formData:any={};
  blogList: any;
  url: any;
  constructor(private router:ActivatedRoute,private WebapiService:WebapiService,
    private urouter:Router) { }

  ngOnInit(): void {
  //   this.router.queryParams.subscribe(params => {
  //     console.log(params); // { order: "popular" }

  //     this.id = params.id;
  //    // console.log(this.order); // popular
  //   }
  // );
  this.url = this.WebapiService.url1;
  this.teamId = this.router.snapshot.queryParamMap.get('id');
  // console.log(this.teamId);
  this.getTeamall();
  if(this.teamId)
{
  this.getTeam(this.teamId);
  this.getBlogsById(this.teamId);
}  


    
    
  }
  getTeam(id:any){
    //const input  = {id:id};
        this.WebapiService.getTeam(id).subscribe((res: any) => {
        console.log(res);
         this.teamList = res[0];
    
    });
  }
  getTeamall(){
 
    this.WebapiService.getTeam().subscribe((res: any) => {
console.log(res);

  this.teamListall = res;

});
}
scheduleMeeting(data:any){
  data.type = 2;
  this.WebapiService.insertContact(data).subscribe((res: any) => {
    console.log(res);
    if(res.msg == 1){
      alert('Submitted Successfully');
      location.reload();
    }
    else{
      alert('error');
    }
  });
  
}

getBlogsById(id:any){
  let val = {
    id:id,
    start:0,
    limit:10
  }
  this.WebapiService.getTeamBlogById(val).subscribe((res: any) => {
    // console.log(res);
    if(res.length > 0){
      this.blogList = res;
    }
  })
}


}
