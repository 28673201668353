import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { WebapiService } from '../services/webapi.service'; 
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
slug:any;
blogList:any;
serviceList:any={};
inquery:any={};
industryList:any
//params:any;
  constructor(private router:ActivatedRoute,private WebapiService:WebapiService,
    private urouter:Router) { }

  ngOnInit() {
    // let pths= this.urouter.url.split('/');
   // console.log(pths[1]);
   // console.log(this.urouter.url);
    this.router.params.subscribe(params => {
      //  console.log(params); // { order: "popular" 
      this.slug = params.id;
      //  console.log(this.slug); // popular
      this.getServiceBySlug(this.slug)
      }
    );

  }

  getCategoryBySlug(slug:any){

    this.WebapiService.getCategoryBySlug(slug).subscribe((res: any) => {
      console.log(res);


    });
  }

  getServiceBySlug(id:any){

    const input  = {slug:id}
    //console.log(categoryList);
     // for (let category of categoryList) {
        //console.log(category.id);
        
        this.WebapiService.getServiceBySlug(input).subscribe((res: any) => {
          // console.log(res);
           this.serviceList = res[0];
       
           this.getBlogsByServiceId(res[0].id);
           this.getBlogsByIndustryId(res[0].id)
         
    });
  //}
  }
  insertInquery(){
    this.inquery.service_id =   this.serviceList.id;
    // console.log(this.inquery);
    this.WebapiService.insertInquery(this.inquery).subscribe((res: any) => {
     alert(res.msg);
      console.log(res);
       //this.serviceList = res[0];

});
  }
  getBlogsByServiceId(id:any){
    this.WebapiService.getBlogsByServiceId(id).subscribe((res: any) => {
      console.log(res);
      this.blogList = res;
      // this.blogList
    });
  }
  getBlogsByIndustryId(id:any){
    console.log(id,'service id ');
    this.WebapiService.getBlogsByIndustryId(id).subscribe((res: any) => {
      console.log(res,'industry');

      this.industryList = res;
     
    });
  }
}