import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../services/webapi.service'; 
import { ActivatedRoute,Router } from '@angular/router';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  slug:any;
  categoryList:any;
  serviceList:any;
  categoryName: any;
  constructor(private router:ActivatedRoute,private WebapiService:WebapiService) { }

  ngOnInit(): void {

    this.router.queryParams.subscribe(params => {
      console.log(params); // { order: "popular" }

      this.slug = params.slug;
     // console.log(this.order); // popular
    }
  );

    this.getCategoryBySlug(this.slug);
    //console.log(this.categoryList);
    
    
  }

  getCategoryBySlug(id:string){
    const input  = {slug:id}

    this.WebapiService.getCategoryBySlug(input).subscribe((res: any) => {
      
     this.categoryList = res[0];
     this.categoryName = this.categoryList.category_name;
     //console.log(this.categoryList);
     this.getService(this.categoryList.id);
    });
  }
  getService(id:any){

   // const input  = {slug:id}
 //console.log(id);
        this.WebapiService.getServices(id).subscribe((res: any) => {
          console.log(res);
           this.serviceList = res;
    
    });
  //}
  }


}
