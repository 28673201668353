import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { WebapiService } from '../services/webapi.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {
  formData:any={};
  regData:any={};
  lStatus:any;
  rStatus:any;
  categoryList:any;
  constructor(private router:ActivatedRoute,private urouter:Router,private WebapiService:WebapiService,private notifyService : NotificationService) { }

  ngOnInit(): void {
    this.getCategory();
  }
  getCategory(){

    this.WebapiService.getCategory().subscribe((res: any) => {
      console.log(res);
      this.categoryList = res;
    //for (let category of res) {
  //   this.getServices(this.categoryList);
      // this.categoryList = res.data;
    });
  }

  onLogin(data:any){
    this.WebapiService.doLogin(this.formData).subscribe((res:any)=>{
      console.log(res);
      this.lStatus = res;
      if(this.lStatus.status == 1){
        alert("login successfull");
        localStorage.setItem('loginId',res.data[0].id);
        location.reload();
      }
      else{
        alert("invalid credntial");
      }
    });
  }
  onReg(data:any){
    this.WebapiService.insertUser(this.regData).subscribe((res:any)=>{
      console.log(res);
      this.rStatus = res;
      if(this.rStatus.status == 2){
        alert("User already exist");
      }
      else if(this.rStatus.status == 1){
        alert("Registered Successfull")
        location.reload();
      }
      else{
        alert("error");
      }
    });
  }
  }
